<!--
 * @Description: 人行道停车运营管理 运营管理 异常抬杆  abnormalPhenomena
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="abnormalPhenomena-mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select v-model="searchForm.parkId"
                         placeholder="全部">
                <el-option v-for="item in parkNameList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="道闸名称">
              <el-select v-model="searchForm.deviceId"
                         placeholder="全部">
                <el-option v-for="item in gateNameList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="控制类型">
              <el-select v-model="searchForm.opType"
                         placeholder="全部">
                <el-option v-for="item in opTypeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="控制时间">
              <el-date-picker v-model="opTime"
                              type="datetimerange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="handleClickSearch">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>异常抬杆</span>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  :config='tableConfig'
                  @getList="getList">
          <template slot="isFirst">
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      //表格设置
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 10
      },
      tableColumnList: [
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'parkId',
          label: '停车场编号',
        },
        {
          prop: 'deviceName',
          label: '道闸名称',
        },
        {
          prop: 'deviceId',
          label: '道闸编号',
        },
        {
          prop: 'abnormalLiftTime',
          label: '控制时间',
        },
        {
          prop: 'supervisorName',
          label: '控制人',
        },
        {
          prop: 'abnormalLiftReason',
          label: '抬杆备注',
        },
        {
          prop: 'opTypeName',
          label: '操作类型',
        },
      ],
      tableList: {
        list: [
        ],
      },
      // 查询参数
      searchForm: {},
      pageNum: 1,
      pageSize: 10,
      opTime: null,
      // 时间快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      value1: '',
      value2: '',
      parkNameList: [],// 停车场列表
      gateNameList: [],// 控制类型列表
      opTypeList: []// 道闸名称列表
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryParkList()
    this.queryGateList()
    this.queryOpTypeList()
    this.queryTableList()
  },
  //方法集合
  methods: {
    // 表单查询
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$abnormalPhenomena.listByPage(this.searchForm).then((response) => {
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 点击查询
    handleClickSearch () {
      if (this.opTime) {
        this.searchForm.startTime = this.opTime[0]
        this.searchForm.endTime = this.opTime[1]
      } else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.searchForm = {}
      this.opTime = null
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    // 停车场
    queryParkList () {
      let info = {
        columnName: ["park_id", "park_name"],
        tableName: "tb_park",
        whereStr: [
          {
            colName: "park_type_code",
            value: '2' //这个是停车场类型 1是路内2是路外
          }
        ],
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    // 道闸
    queryGateList () {
      let info = {
        columnName: ["device_id", "device_name"],
        tableName: "tb_video",
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.gateNameList = response.resultEntity
      })
    },
    // 控制类型
    queryOpTypeList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '6eb4ccb5d73f11ec966bf270b872c0e1',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.opTypeList = response.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
// 最外层div
.abnormalPhenomena-mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>
